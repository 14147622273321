import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

export const youtube: MusicService = {
  id: 'youtube',
  shortName: 'YouTube',
  name: 'YouTube',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: false,
  isSupported: true,
  color: '#FF0000',
  logo: {
    light: nextAsset('images/music-services/youtube-light.svg'),
    dark: nextAsset('images/music-services/youtube-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/youtube-light-padding.svg'),
    dark: nextAsset('images/music-services/youtube-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/youtube-color-light.svg'),
    dark: nextAsset('images/music-services/youtube-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/youtube-color-light-padding.svg'),
    dark: nextAsset('images/music-services/youtube-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/youtube-dark.svg')
};
