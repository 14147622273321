import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

export const napster: MusicService = {
  id: 'napster',
  shortName: 'Napster',
  name: 'Napster',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: true,
  isSupported: true,
  color: '#2ca6de',
  logo: {
    light: nextAsset('images/music-services/napster-light.svg'),
    dark: nextAsset('images/music-services/napster-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/napster-light-padding.svg'),
    dark: nextAsset('images/music-services/napster-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/napster-color-light.svg'),
    dark: nextAsset('images/music-services/napster-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/napster-color-light-padding.svg'),
    dark: nextAsset('images/music-services/napster-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/napster-dark.svg')
};
