import { Config, ConfigEnvironment } from './ConfigType';

export const config: Config = {
  env: ConfigEnvironment.PRODUCTION,
  release: '9679cb546f3e1c422cb013b87b1706fbf4046ec3',
  sentryDSN: 'https://7bab568d62864af39ab6df3d266ea9a7@sentry.io/2709454',
  smartlinksDomain: 'https://music24.com',
  smartlinksAppDomain: 'https://app.music24.com',
  fymServerAPI: 'https://api.freeyourmusic.com/',
  appleAffiliateToken: '1001l38Lj',
  plausible: {
    siteId: 'fym.fm',
    url: 'https://plausible.freeyourmusic.com/'
  }
};
