import { useEffect } from 'react';
import { SMLAppMessage, SMLAppMessages } from './types';

export const useListenForParentIframeMessage = (messageId: SMLAppMessages, callback: (data: SMLAppMessage) => void) => {
  useEffect(() => {
    const onMessage = (event: MessageEvent<string>) => {
      try {
        const data = JSON.parse(event.data);
        if (data.type !== messageId) {
          return;
        }
        console.log('Handled App event', data);
        callback(data);
      } catch (e) {
        console.error(e);
      }
    };
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [callback, messageId]);
};
