import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

export const vkMusic: MusicService = {
  id: 'vk-music',
  appId: 'vk',
  shortName: 'VK Music',
  name: 'VK Music (VKontakte Music / BOOM)',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: false,
  isSupported: true,
  color: '#5181b8',
  logo: {
    light: nextAsset('images/music-services/vk-light.svg'),
    dark: nextAsset('images/music-services/vk-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/vk-light-padding.svg'),
    dark: nextAsset('images/music-services/vk-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/vk-color-light.svg'),
    dark: nextAsset('images/music-services/vk-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/vk-color-light-padding.svg'),
    dark: nextAsset('images/music-services/vk-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/vk-color-light.svg')
};
