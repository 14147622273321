import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil';
import { faCaretUp } from '@fortawesome/pro-light-svg-icons/faCaretUp';
import { faCaretDown } from '@fortawesome/pro-light-svg-icons';
import { SerializedWidget, SMLPreviewMessages } from './types';
import { useSendMessageToParent } from './useSendMessageToParent';

export const SmartLinkWidgetEditOverlay: React.FC<{
  widget: SerializedWidget;
}> = ({ widget }) => {
  const onUpClick = useSendMessageToParent(SMLPreviewMessages.up, widget.uuid);
  const onDownClick = useSendMessageToParent(SMLPreviewMessages.down, widget.uuid);
  const onEditClick = useSendMessageToParent(SMLPreviewMessages.edit, widget.uuid);
  return (
    <div className="smartlink-widget-edit-mode-overlay">
      <Button onClick={onUpClick} href="#" color="primary" outline>
        <FontAwesomeIcon icon={faCaretUp} />
      </Button>
      <Button onClick={onEditClick} href="#" color="primary" outline>
        <FontAwesomeIcon icon={faPencil} />
      </Button>
      <Button onClick={onDownClick} href="#" color="primary" outline>
        <FontAwesomeIcon icon={faCaretDown} />
      </Button>
    </div>
  );
};
