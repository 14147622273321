import React, { useMemo } from 'react';
import { SerializedPlayerWidget, SmartLinkWidgetType } from '../../types';
import { SmartLinkWidget } from '../types';
import { getPlayerWidgetHeight } from './utils';

const PlayerWidgetContent: React.FC<{ data: SerializedPlayerWidget['data'] }> = ({ data }) => {
  const { _height, _src } = useMemo(() => {
    const { embedUrl, type, version, height } = data;
    if (version === undefined) {
      return { _src: embedUrl, _height: getPlayerWidgetHeight(type) };
    }

    // Version 1 has no models in DB
    // Version 2 contains URL with height built in
    return { _src: embedUrl, _height: height };
  }, [data]);

  if (_height === undefined || _src === undefined) {
    return null;
  }

  return (
    <iframe
      title="Player widget"
      width="100%"
      height={_height}
      src={_src}
      frameBorder="0"
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      style={{
        border: 'none',
        position: 'relative',
        overflow: 'hidden',
        maxWidth: '100%'
      }}
    />
  );
};

export const PlayerWidget: SmartLinkWidget<SerializedPlayerWidget> = {
  type: SmartLinkWidgetType.player,
  view: ({ widget: { data } }) => <PlayerWidgetContent data={data} />,
  embed: ({ widget: { data } }) => <PlayerWidgetContent data={data} />
};
