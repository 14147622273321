export enum SmartLinkWidgetType {
  info = 'info',
  image = 'image',
  link = 'link',
  socialLinks = 'socialLinks',
  player = 'player',
  profile = 'profile'
}

export enum PlayerWidgetType {
  normal = 'normal',
  compact = 'compact'
}

export enum LinkType {
  generic = 'generic',

  amazonmusic = 'amazonmusic',
  applemusic = 'applemusic',
  deezer = 'deezer',
  pandora = 'pandora',
  soundcloud = 'soundcloud',
  spotify = 'spotify',
  tidal = 'tidal',
  youtube = 'youtube',
  youtubemusic = 'youtubemusic',
  napster = 'napster',
  qobuz = 'qobuz',
  qqmusic = 'qqmusic',
  yandexmusic = 'yandexmusic',
  vkmusic = 'vkmusic',
  anghami = 'anghami',
  zvuk = 'zvuk',
  gaana = 'gaana',
  jiosaavn = 'jiosaavn',
  resso = 'resso',
  boomplay = 'boomplay',

  facebook = 'facebook',
  instagram = 'instagram',
  twitter = 'twitter',
  linkedin = 'linkedin',
  tiktok = 'tiktok',
  reddit = 'reddit',
  snapchat = 'snapchat'
}

interface SerializedFile {
  id: number;
  uuid: string;
  url: string;
}

export interface SerializedAccount {
  id: number;
  name: string | null;
  avatarFile: SerializedFile | null;
  avatarProcessedUrl: string;
  slug: string | null;
  plausibleDomain: string | null;
  customDomain: string | null;
  shareUrl: string;
}

export enum SerializedAccountTrackerType {
  googleTagManager = 'googleTagManager',
  googleAnalyticsLegacy = 'googleAnalyticsLegacy',
  googleGlobalSite = 'googleGlobalSite',
  googleAnalytics = 'googleAnalytics',
  facebookPixel = 'facebookPixel',
  plausible = 'plausible'
}
export type SerializedAccountTracker = {
  type: SerializedAccountTrackerType;
  data: Record<string, string>;
  trackerId: string;
};

export interface SerializedSocialLink {
  id: number;
  url: string;
  type: LinkType;
}

export interface SerializedSmartLinkVersion {
  name: string;
  slug: string;
  theme: SmartLinkTheme;
  metaTitle: string | null;
  metaDescription: string | null;
  widgets: SerializedWidget[] | null;
  backgroundFile: SerializedFile | null;
  backgroundProcessedUrl: string;
}
interface SerializedSmartLinkBase {
  id: number;
  accountId: number;
  shareUrl: string | null;
  embedUrl: string | null;
}

export interface SerializedSmartLinkPublished extends SerializedSmartLinkBase {
  publishedSmartLinkVersion: SerializedSmartLinkVersion;
  recentSmartLinkVersion: null;
}
export interface SerializedSmartLinkPreview extends SerializedSmartLinkBase {
  publishedSmartLinkVersion: null;
  recentSmartLinkVersion: SerializedSmartLinkVersion;
}
export type SerializedSmartLink = SerializedSmartLinkPublished | SerializedSmartLinkPreview;

export enum InfoWidgetType {
  title = 'title',
  normal = 'normal',
  small = 'small'
}

export type SerializedWidgetData = {
  [SmartLinkWidgetType.profile]: Record<string, never>;
  [SmartLinkWidgetType.info]: { text: string; type: InfoWidgetType };
  [SmartLinkWidgetType.image]: {
    image: SerializedFile;
    imageProcessedUrl: string;
  };
  [SmartLinkWidgetType.link]: {
    url: string;
    label: string | null;
    type: LinkType;
    isMusicService: boolean;
  };
  [SmartLinkWidgetType.socialLinks]: { data: SerializedSocialLink[] };
  [SmartLinkWidgetType.player]: {
    url: string;
    embedUrl?: string;
    type?: PlayerWidgetType;
    musicServiceType: LinkType;
    version?: number;
    height?: number;
  };
};

export type GenericSerializedWidget<T extends SmartLinkWidgetType> = {
  uuid: string;
  position: number;
  smartLinkId: number;
  type: T;
  data: SerializedWidgetData[T];
};

export type SerializedInfoWidget = GenericSerializedWidget<SmartLinkWidgetType.info>;

export type SerializedImageWidget = GenericSerializedWidget<SmartLinkWidgetType.image>;

export type SerializedLinkWidget = GenericSerializedWidget<SmartLinkWidgetType.link>;

export type SerializedSocialLinksWidget = GenericSerializedWidget<SmartLinkWidgetType.socialLinks>;

export type SerializedPlayerWidget = GenericSerializedWidget<SmartLinkWidgetType.player>;

export type SerializedProfileWidget = GenericSerializedWidget<SmartLinkWidgetType.profile>;

export type SerializedWidget =
  | SerializedInfoWidget
  | SerializedImageWidget
  | SerializedLinkWidget
  | SerializedSocialLinksWidget
  | SerializedPlayerWidget
  | SerializedProfileWidget;

export interface PublicAccountSmartLinkAPIResponse {
  account: SerializedAccount;
  socialLinks: SerializedSocialLink[];
  smartLink: SerializedSmartLink;
  accountTrackers: SerializedAccountTracker[];
}

export interface SMLBasePageProps {
  smartLinkResponse: PublicAccountSmartLinkAPIResponse | null;
  isEditMode: boolean;
  error: boolean;
  theme: SmartLinkTheme;
}

export interface SMLPageProps extends SMLBasePageProps {
  isArtist: boolean;
  isEmbed: boolean;
  isEditMode: boolean;
}

export enum SmartLinkTheme {
  chill = 'chill',
  deep = 'deep',
  dark = 'dark',
  loli = 'loli'
}

export const defaultTheme = SmartLinkTheme.deep;

export enum SMLPreviewMessages {
  up = 'SMLPreview.up',
  down = 'SMLPreview.down',
  edit = 'SMLPreview.edit'
}
export interface SMLPreviewMessageData {
  type: SMLPreviewMessages;
  widgetUUID: string;
}
export enum SMLAppMessages {
  smartLinkUpdate = 'SMLApp.smartLinkUpdate'
}
interface SMLAppMessageData<Type extends SMLAppMessages, Data> {
  type: Type;
  data: Data;
}

export type SMLAppMessageSmartLinkUpdate = SMLAppMessageData<
  SMLAppMessages.smartLinkUpdate,
  { smartLink: SerializedSmartLink }
>;

export type SMLAppMessage = SMLAppMessageSmartLinkUpdate;
