import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

export const amazonMusic: MusicService = {
  id: 'amazon-music',
  shortName: 'Amazon Music',
  name: 'Amazon Music',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: true,
  isSupported: true,
  color: '#0077C1',
  logo: {
    light: nextAsset('images/music-services/amazon-light.svg'),
    dark: nextAsset('images/music-services/amazon-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/amazon-light-padding.svg'),
    dark: nextAsset('images/music-services/amazon-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/amazon-color-light.svg'),
    dark: nextAsset('images/music-services/amazon-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/amazon-color-light-padding.svg'),
    dark: nextAsset('images/music-services/amazon-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/amazon-color-dark.svg')
};
