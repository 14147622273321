import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

export const soundcloud: MusicService = {
  id: 'soundcloud',
  appId: 'sound-cloud',
  shortName: 'SoundCloud',
  name: 'SoundCloud',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: false,
  isSupported: true,
  color: '#ff5500',
  logo: {
    light: nextAsset('images/music-services/soundcloud-light.svg'),
    dark: nextAsset('images/music-services/soundcloud-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/soundcloud-light-padding.svg'),
    dark: nextAsset('images/music-services/soundcloud-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/soundcloud-color-light.svg'),
    dark: nextAsset('images/music-services/soundcloud-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/soundcloud-color-light-padding.svg'),
    dark: nextAsset('images/music-services/soundcloud-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/soundcloud-dark.svg')
};
