import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

export const appleMusic: MusicService = {
  id: 'apple-music',
  shortName: 'Apple Music',
  name: 'Apple Music',
  isComingSoon: false,
  areStatsSupported: true,
  areStatsComingSoon: false,
  isSupported: true,
  color: '#B166CC',
  logo: {
    light: nextAsset('images/music-services/applefake-light.svg'),
    dark: nextAsset('images/music-services/applefake-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/applefake-light-padding.svg'),
    dark: nextAsset('images/music-services/applefake-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/applefake-color-light.svg'),
    dark: nextAsset('images/music-services/applefake-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/applefake-color-light-padding.svg'),
    dark: nextAsset('images/music-services/applefake-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/applefake-color-dark.svg')
};
