import { SmartLinkWidget } from './types';
import { SmartLinkWidgetType } from '../types';
import { InfoWidget } from './InfoWidget';
import { ImageWidget } from './ImageWidget';
import { LinkWidget } from './LinkWidget';
import { SocialLinkWidget } from './SocialLinkWidget';
import { PlayerWidget } from './playerWidget/PlayerWidget';
import { ProfileWidget } from './ProfileWidget';

export const allSmartLinkWidgets: SmartLinkWidget<any>[] = [
  InfoWidget,
  ImageWidget,
  LinkWidget,
  SocialLinkWidget,
  PlayerWidget,
  ProfileWidget
];

export const smartLinkWidgets = (() =>
  allSmartLinkWidgets.reduce((result, smartLink) => {
    // eslint-disable-next-line no-param-reassign
    result[smartLink.type] = smartLink;
    return result;
  }, {} as Record<SmartLinkWidgetType, SmartLinkWidget<any>>))();
