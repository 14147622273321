import { CSSProperties } from 'react';
import { faGlobeAmericas } from '@fortawesome/pro-light-svg-icons/faGlobeAmericas';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faSoundcloud } from '@fortawesome/free-brands-svg-icons/faSoundcloud';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faAmazon } from '@fortawesome/free-brands-svg-icons/faAmazon';
import { faApple } from '@fortawesome/free-brands-svg-icons/faApple';
import { faDeezer } from '@fortawesome/free-brands-svg-icons/faDeezer';
import { faSpotify } from '@fortawesome/free-brands-svg-icons/faSpotify';
import { faMusic } from '@fortawesome/pro-solid-svg-icons/faMusic';
import { faNapster } from '@fortawesome/free-brands-svg-icons/faNapster';
import { faQq } from '@fortawesome/free-brands-svg-icons/faQq';
import { faYandexInternational } from '@fortawesome/free-brands-svg-icons/faYandexInternational';
import { faVk } from '@fortawesome/free-brands-svg-icons/faVk';
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok';
import { faSnapchat } from '@fortawesome/free-brands-svg-icons/faSnapchat';
import { faReddit } from '@fortawesome/free-brands-svg-icons/faReddit';
import { LinkType } from './types';
import { MusicService } from '../../components/musicServices/services/types';
import { youtubeMusic } from '../../components/musicServices/services/YouTubeMusic';
import { soundcloud } from '../../components/musicServices/services/SoundCloud';
import { youtube } from '../../components/musicServices/services/YouTube';
import { spotify } from '../../components/musicServices/services/Spotify';
import { amazonMusic } from '../../components/musicServices/services/AmazonMusic';
import { appleMusic } from '../../components/musicServices/services/AppleMusic';
import { deezer } from '../../components/musicServices/services/Deezer';
import { pandora } from '../../components/musicServices/services/Pandora';
import { tidal } from '../../components/musicServices/services/Tidal';
import { napster } from '../../components/musicServices/services/Napster';
import { qobuz } from '../../components/musicServices/services/Qobuz';
import { qqMusic } from '../../components/musicServices/services/QQMusic';
import { yandexMusic } from '../../components/musicServices/services/YandexMusic';
import { vkMusic } from '../../components/musicServices/services/VKMusic';
import { anghami } from '../../components/musicServices/services/Anghami';
import { zvuk } from '../../components/musicServices/services/Zvuk';
import { gaana } from '../../components/musicServices/services/Gaana';
import { jiosaavn } from '../../components/musicServices/services/JioSaavn';
import { resso } from '../../components/musicServices/services/Resso';
import { boomplay } from '../../components/musicServices/services/Boomplay';

export function iconForLink(type: LinkType) {
  switch (type) {
    case LinkType.amazonmusic:
      return faAmazon;
    case LinkType.applemusic:
      return faApple;
    case LinkType.deezer:
      return faDeezer;
    case LinkType.pandora:
      return faMusic; // Missing brand icon
    case LinkType.soundcloud:
      return faSoundcloud;
    case LinkType.spotify:
      return faSpotify;
    case LinkType.tidal:
      return faMusic; // Missing brand icon
    case LinkType.youtube:
      return faYoutube;
    case LinkType.youtubemusic:
      return faYoutube;
    case LinkType.napster:
      return faNapster;
    case LinkType.qobuz:
      return faMusic; // Missing brand icon
    case LinkType.qqmusic:
      return faQq;
    case LinkType.yandexmusic:
      return faYandexInternational;
    case LinkType.vkmusic:
      return faVk;
    case LinkType.anghami:
      return faMusic; // Missing brand icon
    case LinkType.zvuk:
      return faMusic; // Missing brand icon
    case LinkType.gaana:
      return faMusic; // Missing brand icon
    case LinkType.jiosaavn:
      return faMusic; // Missing brand icon
    case LinkType.boomplay:
      return faMusic; // Missing brand icon
    case LinkType.resso:
      return faMusic; // Missing brand icon
    case LinkType.facebook:
      return faFacebookF;
    case LinkType.instagram:
      return faInstagram;
    case LinkType.twitter:
      return faTwitter;
    case LinkType.linkedin:
      return faLinkedinIn;
    case LinkType.tiktok:
      return faTiktok;
    case LinkType.snapchat:
      return faSnapchat;
    case LinkType.reddit:
      return faReddit;
    case LinkType.generic:
    default:
      return faGlobeAmericas;
  }
}

export function musicServiceForLink(type: LinkType): MusicService | null {
  switch (type) {
    case LinkType.amazonmusic:
      return amazonMusic;
    case LinkType.applemusic:
      return appleMusic;
    case LinkType.deezer:
      return deezer;
    case LinkType.pandora:
      return pandora;
    case LinkType.soundcloud:
      return soundcloud;
    case LinkType.spotify:
      return spotify;
    case LinkType.tidal:
      return tidal;
    case LinkType.youtube:
      return youtube;
    case LinkType.youtubemusic:
      return youtubeMusic;
    case LinkType.napster:
      return napster;
    case LinkType.qobuz:
      return qobuz;
    case LinkType.qqmusic:
      return qqMusic;
    case LinkType.yandexmusic:
      return yandexMusic;
    case LinkType.vkmusic:
      return vkMusic;
    case LinkType.anghami:
      return anghami;
    case LinkType.zvuk:
      return zvuk;
    case LinkType.gaana:
      return gaana;
    case LinkType.jiosaavn:
      return jiosaavn;
    case LinkType.resso:
      return resso;
    case LinkType.boomplay:
      return boomplay;
    default:
      return null;
  }
}

export function stylesForLink(type: LinkType): CSSProperties {
  const musicService = musicServiceForLink(type);
  if (musicService) {
    return { backgroundColor: musicService.color };
  }
  switch (type) {
    case LinkType.facebook:
      return { backgroundColor: '#1877f2' };
    case LinkType.instagram:
      return { backgroundColor: '#e1306c' };
    case LinkType.twitter:
      return { backgroundColor: '#1da1f2' };
    case LinkType.linkedin:
      return { backgroundColor: '#0077b5' };
    case LinkType.tiktok:
      return { backgroundColor: '#000000' };
    case LinkType.snapchat:
      return { backgroundColor: '#FFFC00', color: '#000000' };
    case LinkType.reddit:
      return { backgroundColor: '#FF4500' };
    case LinkType.generic:
    default:
      return {};
  }
}
