import React from 'react';
import classNames from 'classnames';
import { SmartLinkWidget } from './types';
import { InfoWidgetType, SerializedInfoWidget, SmartLinkWidgetType } from '../types';

const ComponentForInfoType = (type: SerializedInfoWidget['data']['type']) => {
  switch (type) {
    case InfoWidgetType.title:
      return 'h1';
    case InfoWidgetType.normal:
      return 'div';
    case InfoWidgetType.small:
    default:
      return 'div';
  }
};

const InfoWidgetContent: React.FC<{ data: SerializedInfoWidget['data'] }> = ({ data }) => {
  const Component = ComponentForInfoType(data.type);
  return (
    <Component className={classNames('smartlink-widget-info', `widget-info-${data.type}`)}>
      <span className="smartlink-widget-info-text">
        {data.text.split('\n').map((item, index, array) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={`text-${index}`}>
            {item}
            {array.length - 1 === index ? null : <br />}
          </React.Fragment>
        ))}
      </span>
    </Component>
  );
};

export const InfoWidget: SmartLinkWidget<SerializedInfoWidget> = {
  type: SmartLinkWidgetType.info,
  view: ({ widget: { data } }) => <InfoWidgetContent data={data} />,
  embed: ({ widget: { data } }) => <InfoWidgetContent data={data} />
};
