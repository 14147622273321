import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Col, Container, Row } from 'reactstrap';
import { PublicAccountSmartLinkAPIResponse, SerializedWidget, SmartLinkWidgetType } from './types';
import { SmartLinkFooter } from './SmartLinkFooter';
import { config } from '../../config/config';
import { CookieConsentBar } from '../../components/CookieConsent/CookieConsentBar';
import { useNeedsCookieConsentBar } from '../../components/trackers/useTrackers';
import { SmartLinkWidget } from './SmartLinkWidget';

const mainSideWidgetsTypes = [
  SmartLinkWidgetType.info,
  SmartLinkWidgetType.link,
  SmartLinkWidgetType.socialLinks,
  SmartLinkWidgetType.profile
];

export const SmartLink: React.FC<{
  smartLinkResponse: PublicAccountSmartLinkAPIResponse;
  isEmbed?: boolean;
  isEditMode?: boolean;
}> = ({ smartLinkResponse, isEmbed, isEditMode }) => {
  const showCookieConsent = useNeedsCookieConsentBar(smartLinkResponse?.accountTrackers);

  const { smartLink } = smartLinkResponse;
  const smartLinkVersion = smartLink.recentSmartLinkVersion ?? smartLink.publishedSmartLinkVersion;

  const { widgets } = smartLinkVersion;
  const sortedWidgets = useMemo(() => widgets?.sort((a, b) => a.position - b.position) ?? [], [widgets]);
  const widgetsDividedToSides = useMemo(() => {
    const left: SerializedWidget[] = [];
    const right: SerializedWidget[] = [];
    sortedWidgets.forEach((widget) => {
      if (mainSideWidgetsTypes.includes(widget.type)) {
        left.push(widget);
      } else {
        right.push(widget);
      }
    });

    return {
      left,
      right
    };
  }, [sortedWidgets]);

  return (
    <div
      className={classNames('smartlink smartlink-normal', {
        'smartlink-embed': isEmbed,
        'smartlink-custom-bg': smartLinkVersion.backgroundFile !== null,
        'smartlink-normal': !isEmbed
      })}
    >
      <div className="smartlink-background">
        <div
          className="smartlink-background-inner"
          style={{
            backgroundImage: `url("${smartLinkVersion.backgroundProcessedUrl}")`
          }}
        />
      </div>
      <div className="smartlink-content">
        <Container className="d-none d-lg-block">
          <Row>
            {widgetsDividedToSides.left.length > 0 && (
              <Col className="smartlink-content-left">
                <div className="smartlink-widgets">
                  {widgetsDividedToSides.left.map((w) => (
                    <SmartLinkWidget
                      widget={w}
                      smartLinkResponse={smartLinkResponse}
                      key={w.uuid}
                      isEditMode={isEditMode ?? false}
                    />
                  ))}
                </div>
              </Col>
            )}
            {widgetsDividedToSides.right.length > 0 && (
              <Col className="smartlink-content-right">
                <div className="smartlink-widgets">
                  {widgetsDividedToSides.right.map((w) => (
                    <SmartLinkWidget
                      widget={w}
                      smartLinkResponse={smartLinkResponse}
                      key={w.uuid}
                      isEditMode={isEditMode ?? false}
                    />
                  ))}
                </div>
              </Col>
            )}
          </Row>
        </Container>
        <div className="smartlink-widgets d-block d-lg-none">
          {sortedWidgets.map((w) => (
            <SmartLinkWidget
              widget={w}
              smartLinkResponse={smartLinkResponse}
              key={w.uuid}
              isEditMode={isEditMode ?? false}
            />
          ))}
        </div>
        <SmartLinkFooter url={`${config.smartlinksDomain}`} embed={isEmbed ?? false} />
        {showCookieConsent && !isEditMode && <CookieConsentBar />}
      </div>
    </div>
  );
};
