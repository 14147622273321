'use client';

import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import qs from 'qs';
import { PublicAccountSmartLinkAPIResponse } from './smartLinks/types';
import { useTrackers } from '../components/trackers/useTrackers';

export function useSmartLinkDetails(smartLinkResponse: PublicAccountSmartLinkAPIResponse | null) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();

  useTrackers(smartLinkResponse?.accountTrackers);
  useEffect(() => {
    // When we publish new version of SmartLink we offer user to open the page with freshly published smart link
    // It may happen that CloudFlare still cached it, so we open the page with random parameter
    // Here we will silently replace the URL on frontend side, so if user copies URL then he has nice one to share
    const randParam = searchParams.get('rand');
    if (randParam) {
      console.log(`Replacing current URL ${pathname}; ${JSON.stringify(searchParams.entries())}`);
      const query: Record<string, string> = {};
      searchParams.forEach((v, k) => {
        if (k === 'rand') {
          return;
        }
        query[k] = v;
      });
      console.log(`with ${pathname}; ${JSON.stringify(query)}`);
      router.replace(`${pathname}?${qs.stringify(query)}`);
    }
  }, [pathname, router, searchParams]);
  const smartLinkVersion =
    smartLinkResponse?.smartLink.recentSmartLinkVersion ?? smartLinkResponse?.smartLink.publishedSmartLinkVersion;

  return {
    title: smartLinkVersion?.metaTitle ?? 'Smart Link',
    description: smartLinkVersion?.metaDescription ?? ''
  };
}
