import { useCallback, useEffect } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';

type CookieListener = (alreadyConsented: boolean) => void;
let cookieConsentListeners: CookieListener[] = [];
const registerCookieConsentListener: (listener: CookieListener) => () => void = (listener) => {
  cookieConsentListeners.push(listener);
  return () => {
    cookieConsentListeners = cookieConsentListeners.filter((l) => l !== listener);
  };
};
const updateCookieConsentListeners = (value: boolean) => {
  cookieConsentListeners.forEach((listener) => listener(value));
};

export const useCookieConsent = () => {
  const [alreadyConsented, setAlreadyConsented] = useLocalStorage<boolean>('cookieConsent', false);

  const onConsentGiven = useCallback(() => {
    setAlreadyConsented(true);
    updateCookieConsentListeners(true); // call other listeners
  }, [setAlreadyConsented]);

  useEffect(
    () =>
      registerCookieConsentListener((ac) => {
        setAlreadyConsented(ac);
      }),
    [setAlreadyConsented]
  );

  return {
    consented: alreadyConsented,
    onConsentGiven
  };
};
