import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

export const spotify: MusicService = {
  id: 'spotify',
  shortName: 'Spotify',
  name: 'Spotify',
  isComingSoon: false,
  areStatsSupported: true,
  areStatsComingSoon: false,
  isSupported: true,
  color: '#1DB954',
  logo: {
    light: nextAsset('images/music-services/spotify-light.svg'),
    dark: nextAsset('images/music-services/spotify-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/spotify-light-padding.svg'),
    dark: nextAsset('images/music-services/spotify-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/spotify-color-light.svg'),
    dark: nextAsset('images/music-services/spotify-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/spotify-color-light-padding.svg'),
    dark: nextAsset('images/music-services/spotify-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/spotify-dark.svg')
};
