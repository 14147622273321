export enum ConfigEnvironment {
  LOCAL,
  STAGING,
  BETA,
  PRODUCTION
}

export interface Config {
  env: ConfigEnvironment;
  release: string;
  sentryDSN?: string;
  smartlinksDomain: string;
  smartlinksAppDomain: string;
  fymServerAPI: string;
  appleAffiliateToken: string;
  plausible?: {
    siteId: string;
    url: string;
  };
}
