'use client';

import { usePathname } from 'next/navigation';
import { useCallback, useEffect, useMemo } from 'react';
import { logger } from '../../utils/logger';
import { SerializedAccountTracker, SerializedAccountTrackerType } from '../../containers/smartLinks/types';
import { usePlausibleTracker } from './plausible/usePlausible';
import { TrackerEvent } from './types';
import { useSmartLinkContext } from '../../utils/SmartLinkContext';
import { useGoogleAnalytics } from './google/useGoogleAnalytics';
import { useMetaPixel } from './metapixel/useMetaPixel';
import { useIsPreview } from '../../utils/IsPreviewContext';
import { useCookieConsent } from '../CookieConsent/useCookieConsent';

export const trackersWithoutCookies = [SerializedAccountTrackerType.plausible];

export function useNeedsCookieConsentBar(trackers: SerializedAccountTracker[] | undefined) {
  return useMemo(() => {
    if (!trackers) {
      return false;
    }
    return trackers.reduce(
      (previousValue, currentValue) => previousValue || !trackersWithoutCookies.includes(currentValue.type),
      false
    );
  }, [trackers]);
}

export function useTrackersImplementations(trackers: SerializedAccountTracker[] | undefined) {
  const plausible = usePlausibleTracker(trackers);
  const googleAnalytics = useGoogleAnalytics(trackers);
  const metaPixel = useMetaPixel(trackers);

  return useMemo(() => [plausible, googleAnalytics, metaPixel], [googleAnalytics, plausible, metaPixel]);
}

export function useTrackers(trackers: SerializedAccountTracker[] | undefined): void {
  const pathname = usePathname();
  const trackersImplementations = useTrackersImplementations(trackers);

  const isPreview = useIsPreview();
  const { consented } = useCookieConsent();

  useEffect(() => {
    logger(`Cookie consented: ${consented}`);
  }, [consented]);
  useEffect(() => {
    logger(`Initializing trackers`);
    trackersImplementations.forEach((trackerImpl) => {
      logger(`Calling Tracker initTracker: ${trackerImpl.trackerId}`);
      trackerImpl.initTracker?.();
    });
  }, [trackersImplementations]);
  useEffect(() => {
    if (!consented) {
      return;
    }
    logger(`Cookie consent given`);
    trackersImplementations.forEach((trackerImpl) => {
      logger(`Calling Tracker onConsentGiven: ${trackerImpl.trackerId}`);
      trackerImpl.onConsentGiven?.();
    });
  }, [consented, trackersImplementations]);
  useEffect(() => {
    logger(`Page changed: ${pathname}`);

    if (isPreview) {
      logger(`[SKIPPING inPreview: true] Calling Tracker onPageView`);
      return;
    }

    trackersImplementations.forEach((trackerImpl) => {
      logger(`Calling Tracker onPageView: ${trackerImpl.trackerId}`);
      trackerImpl.onPageView(pathname);
    });
  }, [isPreview, pathname, trackersImplementations]);
}

export function useTrackerEventCallback(event: TrackerEvent, eventProps?: Record<string, string | undefined>) {
  const { smartLinkResponse } = useSmartLinkContext();
  const trackers = smartLinkResponse?.accountTrackers;
  const trackersImplementations = useTrackersImplementations(trackers);
  const isPreview = useIsPreview();

  return useCallback(() => {
    if (isPreview) {
      logger(`[SKIPPING inPreview: true] Calling Tracker onEvent, ${event}: ${JSON.stringify(eventProps)}`);
      return;
    }
    trackersImplementations.forEach((trackerImpl) => {
      logger(
        `Calling Tracker onEvent: ${trackerImpl.trackerId} (props:${eventProps ? JSON.stringify(eventProps) : ''})`
      );
      trackerImpl.onEvent(event, eventProps);
    });
  }, [event, eventProps, isPreview, trackersImplementations]);
}
