import React from 'react';
import { SmartLinkWidget } from './types';
import { SerializedImageWidget, SmartLinkWidgetType } from '../types';

const ImageWidgetContent: React.FC<{ data: SerializedImageWidget['data'] }> = ({ data }) => (
  <div className="smartlink-widget-image">
    {/* eslint-disable-next-line @next/next/no-img-element */}
    <img src={data.imageProcessedUrl} alt="Smartlink Widget" className="img-fluid rounded-sm" />
  </div>
);

export const ImageWidget: SmartLinkWidget<SerializedImageWidget> = {
  type: SmartLinkWidgetType.image,
  view: ({ widget: { data } }) => <ImageWidgetContent data={data} />,
  embed: ({ widget: { data } }) => <ImageWidgetContent data={data} />
};
