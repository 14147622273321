import { PlayerWidgetType } from '../../types';

export function getPlayerWidgetHeight(type: PlayerWidgetType | undefined) {
  switch (type) {
    case PlayerWidgetType.compact:
      return 80;
    case PlayerWidgetType.normal:
    default:
      return 380;
  }
}
