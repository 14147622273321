import { useMemo } from 'react';
import { SerializedAccountTrackerType } from '../../../containers/smartLinks/types';
import { TrackerImplementationHook } from '../types';

const googleAnalyticsTypes = [
  SerializedAccountTrackerType.googleTagManager,
  SerializedAccountTrackerType.googleAnalyticsLegacy,
  SerializedAccountTrackerType.googleGlobalSite,
  SerializedAccountTrackerType.googleAnalytics
];

export const useGoogleAnalytics: TrackerImplementationHook = (trackers) => {
  const googleTrackers = useMemo(() => trackers?.filter((t) => googleAnalyticsTypes.includes(t.type)), [trackers]);
  return useMemo(
    () => ({
      initTracker: () => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js`;

        window.dataLayer = window.dataLayer || [];

        function gtag() {
          // eslint-disable-next-line prefer-rest-params
          window.dataLayer.push(arguments);
        }

        window.gtag = gtag;
        window.gtag('js', new Date());
        window.gtag('consent', 'default', {
          analytics_storage: 'denied'
        });
        // Insert our script next to the first script element.
        const first = document.getElementsByTagName('script')[0];
        first?.parentNode?.insertBefore(script, first);
      },
      onPageView: (path) => {
        if (!googleTrackers) {
          return;
        }
        googleTrackers.forEach((tracker) => {
          window.gtag('config', tracker.trackerId, { page_path: path });
        });
      },
      onEvent: (event, params) => {
        if (!googleTrackers) {
          return;
        }
        googleTrackers.forEach((tracker) => {
          window.gtag('event', event, { ...params, send_to: tracker.trackerId });
        });
      },
      onConsentGiven: () => {
        window.gtag('consent', 'update', {
          analytics_storage: 'granted'
        });
      },
      trackerId: 'googleAnalytics'
    }),
    [googleTrackers]
  );
};
