import React, { useContext } from 'react';
import { defaultTheme, PublicAccountSmartLinkAPIResponse } from '../containers/smartLinks/types';

export const SmartLinkContext = React.createContext<{
  smartLinkResponse: PublicAccountSmartLinkAPIResponse | null;
  isArtist?: boolean;
  isEmbed?: boolean;
  isEditMode?: boolean;
  theme: string;
}>({
  smartLinkResponse: null,
  isArtist: undefined,
  isEmbed: undefined,
  isEditMode: undefined,
  theme: defaultTheme
});
export const useSmartLinkContext = () => {
  const smartLinkResponse = useContext(SmartLinkContext);
  return smartLinkResponse;
};
