import React from 'react';
import Logo from '../../../public/statics/logos-sml/logo.svg';
import { FYMTrackerEvent } from '../../components/trackers/types';
import { useFYMPlausible } from '../../components/trackers/plausible/usePlausible';

export const SmartLinkFooter: React.FC<{ url: string; embed: boolean }> = ({ url, embed }) => {
  const onFooterClick = useFYMPlausible(FYMTrackerEvent.footerClicked);
  return (
    <footer className="smartlink-footer pt-3 text-center">
      <a
        href={url}
        className="simple-footer-logo-container"
        onClick={onFooterClick}
        target="_blank"
        rel="noopener noreferrer"
      >
        {!embed && <div>Made with</div>}
        <Logo />
      </a>
    </footer>
  );
};
