import React, { useCallback } from 'react';
import { Button } from 'reactstrap';
import { SmartLinkWidget } from './types';
import { SerializedLinkWidget, SmartLinkWidgetType } from '../types';
import { musicServiceForLink } from '../utils';
import { useTrackerEventCallback } from '../../../components/trackers/useTrackers';
import { TrackerEvent } from '../../../components/trackers/types';
import { useSmartLinkContext } from '../../../utils/SmartLinkContext';

const LinkWidgetContent: React.FC<{ widget: SerializedLinkWidget }> = ({ widget }) => {
  const { isEditMode } = useSmartLinkContext();
  const musicService = musicServiceForLink(widget.data.type);

  const label = widget.data.label ?? 'Open';

  const onClickTracked = useTrackerEventCallback(TrackerEvent.linkClicked, {
    musicService: musicService ? musicService?.name : undefined,
    label: !musicService ? label : undefined
  });

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (isEditMode) {
        event.preventDefault();
        return;
      }
      onClickTracked();
    },
    [isEditMode, onClickTracked]
  );

  const buttonContent = musicService ? (
    <>
      <span className="smartlink-widget-link-text">Open in</span>
      <span className="smartlink-widget-link-icon">
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img src={musicService.logoColor.dark} alt="" className="smartlink-widget-link-ms-logocolor-dark" height={40} />
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src={musicService.logoColor.light}
          alt=""
          className="smartlink-widget-link-ms-logocolor-light"
          height={40}
        />
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img src={musicService.logo.dark} alt="" className="smartlink-widget-link-ms-logo-dark" height={40} />
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img src={musicService.logo.light} alt="" className="smartlink-widget-link-ms-logo-light" height={40} />
      </span>
    </>
  ) : (
    <span className="smartlink-widget-link-text">{label}</span>
  );
  return (
    <div className="smartlink-widget-link">
      <Button color="theme" href={widget.data.url} outline target="_blank" rel="noopener noreferrer" onClick={onClick}>
        {buttonContent}
      </Button>
    </div>
  );
};

export const LinkWidget: SmartLinkWidget<SerializedLinkWidget> = {
  type: SmartLinkWidgetType.link,
  view: ({ widget }) => <LinkWidgetContent widget={widget} />,
  embed: ({ widget }) => <LinkWidgetContent widget={widget} />
};
