import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

export const pandora: MusicService = {
  id: 'pandora',
  shortName: 'Pandora',
  name: 'Pandora',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: true,
  isSupported: true,
  color: '#0089FC',
  logo: {
    light: nextAsset('images/music-services/pandora-light.svg'),
    dark: nextAsset('images/music-services/pandora-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/pandora-light-padding.svg'),
    dark: nextAsset('images/music-services/pandora-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/pandora-color-light.svg'),
    dark: nextAsset('images/music-services/pandora-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/pandora-color-light-padding.svg'),
    dark: nextAsset('images/music-services/pandora-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/pandora-dark.svg')
};
