import { useMemo } from 'react';
import { SerializedAccountTrackerType } from '../../../containers/smartLinks/types';
import { TrackerImplementationHook } from '../types';

export const useMetaPixel: TrackerImplementationHook = (trackers) => {
  const metapixelTrackers = useMemo(
    () => trackers?.filter((t) => t.type === SerializedAccountTrackerType.facebookPixel),
    [trackers]
  );
  return useMemo(
    () => ({
      initTracker: () => {
        if (!metapixelTrackers || typeof window === 'undefined') {
          return;
        }

        /* eslint-disable */
        // Facebook Pixel Code -->
        (function (f: any, b: any, e: any, v: any, n?: any, t?: any, s?: any) {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = '2.0';
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        // End Facebook Pixel Code -->
        /* eslint-enable */
        window.fbq('consent', 'revoke');
        metapixelTrackers.forEach((t) => {
          window.fbq('init', t.trackerId);
        });
      },
      onPageView: () => {
        window.fbq?.('track', 'PageView');
      },
      onEvent: (event, params) => {
        window.fbq?.('trackCustom', event, params);
      },
      onConsentGiven: () => {
        window.fbq?.('consent', 'grant');
      },
      trackerId: 'metapixel'
    }),
    [metapixelTrackers]
  );
};
