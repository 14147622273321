import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Head from 'next/head';
import { makePageTitleSmartLinks } from '../components/utils';

const SmartLinkNotFound = () => {
  const title = makePageTitleSmartLinks('Not Found 404');
  const description = 'Not Found 404';
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta property="twitter:title" content={title} key="twitter-title" />
        <meta property="og:title" content={title} key="fb-title" />
        <meta itemProp="description" content={description} key="itemProp-description" />
        <meta name="description" content={description} key="description" />
        <meta property="og:description" content={description} key="fb-description" />
        <meta property="twitter:description" content={description} key="twitter-description" />
      </Head>
      <body>
        <div className="h-100 d-flex flex-column justify-content-center">
          <Container className="text-center py-5">
            <Row>
              <Col className="py-3">
                <h1>Not Found 404</h1>
              </Col>
              <Col xs={12}>
                <p>This page could not be found</p>
              </Col>
            </Row>
          </Container>
        </div>
      </body>
    </>
  );
};

export const SmartLinkNotFoundPage = React.memo(SmartLinkNotFound);
