import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

export const zvuk: MusicService = {
  id: 'zvuk',
  shortName: 'Zvuk',
  name: 'Zvuk (Звук)',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: false,
  isSupported: true,
  color: '#47e0dc',
  logo: {
    light: nextAsset('images/music-services/zvuk-light.svg'),
    dark: nextAsset('images/music-services/zvuk-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/zvuk-light-padding.svg'),
    dark: nextAsset('images/music-services/zvuk-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/zvuk-color-light.svg'),
    dark: nextAsset('images/music-services/zvuk-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/zvuk-color-light-padding.svg'),
    dark: nextAsset('images/music-services/zvuk-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/zvuk-color-dark.svg')
};
