import React from 'react';
import classNames from 'classnames';
import { PublicAccountSmartLinkAPIResponse, SerializedWidget } from './types';
import { smartLinkWidgets } from './widgets';
import { SmartLinkWidgetEditOverlay } from './SmartLinkWidgetEditOverlay';

export const SmartLinkWidget: React.FC<{
  widget: SerializedWidget;
  isEditMode: boolean;
  smartLinkResponse: PublicAccountSmartLinkAPIResponse;
}> = ({ widget, smartLinkResponse, isEditMode }) => {
  const widgetView = smartLinkWidgets[widget.type];

  if (!widgetView) {
    return null;
  }

  return (
    <div key={widget.uuid} className={classNames('smartlink-widget', { 'smartlink-widget-edit-mode': isEditMode })}>
      <div className="smartlink-widget-content">
        {widgetView.view({ widget, smartLinkResponse })}
        {isEditMode && <SmartLinkWidgetEditOverlay widget={widget} />}
      </div>
    </div>
  );
};
