import React, { useCallback } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import Head from 'next/head';
import { makePageTitleSmartLinks } from '../components/utils';

const SmartLinkError = () => {
  const onRetry = useCallback(() => {
    window.location.reload();
  }, []);
  const title = makePageTitleSmartLinks('Error occurred');
  const description = 'This should not happen, sorry';
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta property="twitter:title" content={title} key="twitter-title" />
        <meta property="og:title" content={title} key="fb-title" />
        <meta itemProp="description" content={description} key="itemProp-description" />
        <meta name="description" content={description} key="description" />
        <meta property="og:description" content={description} key="fb-description" />
        <meta property="twitter:description" content={description} key="twitter-description" />
      </Head>
      <body>
        <div className="h-100 d-flex flex-column justify-content-center">
          <Container className="text-center py-5">
            <Row>
              <Col className="py-3">
                <h1>This should not happen, sorry</h1>
              </Col>
              <Col xs={12}>
                <p>We had some problems loading the page, please try again</p>
              </Col>
              <Col xs={12}>
                <Button color="primary" onClick={onRetry}>
                  Try again
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </body>
    </>
  );
};

export const SmartLinkErrorPage = React.memo(SmartLinkError);
