import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

export const qobuz: MusicService = {
  id: 'qobuz',
  shortName: 'Qobuz',
  name: 'Qobuz',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: false,
  isSupported: true,
  color: '#0070ef',
  logo: {
    light: nextAsset('images/music-services/qobuz-light.svg'),
    dark: nextAsset('images/music-services/qobuz-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/qobuz-light-padding.svg'),
    dark: nextAsset('images/music-services/qobuz-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/qobuz-color-light.svg'),
    dark: nextAsset('images/music-services/qobuz-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/qobuz-color-light-padding.svg'),
    dark: nextAsset('images/music-services/qobuz-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/qobuz-dark.svg')
};
