import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SmartLinkWidget } from './types';
import { SerializedSocialLink, SerializedSocialLinksWidget, SmartLinkWidgetType } from '../types';
import { iconForLink, stylesForLink } from '../utils';
import { useTrackerEventCallback } from '../../../components/trackers/useTrackers';
import { TrackerEvent } from '../../../components/trackers/types';
import { useSmartLinkContext } from '../../../utils/SmartLinkContext';

const SocialLinksWidgetContentLink: React.FC<{ link: SerializedSocialLink }> = ({ link }) => {
  const { isEditMode } = useSmartLinkContext();
  const onClickTracked = useTrackerEventCallback(TrackerEvent.socialLinkClicked, {
    type: link.type
  });
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (isEditMode) {
        event.preventDefault();
        return;
      }
      onClickTracked();
    },
    [isEditMode, onClickTracked]
  );
  return (
    <a
      href={link.url}
      target="_blank"
      rel="noopener noreferrer"
      className="smartlink-social-links-link"
      key={link.url}
      style={stylesForLink(link.type)}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={iconForLink(link.type)} />
    </a>
  );
};

const SocialLinksWidgetContent: React.FC<{
  socialLinks: SerializedSocialLink[];
}> = ({ socialLinks }) => (
  <div className="smartlink-social-links">
    {socialLinks.map((link) => (
      <SocialLinksWidgetContentLink key={link.url} link={link} />
    ))}
  </div>
);

export const SocialLinkWidget: SmartLinkWidget<SerializedSocialLinksWidget> = {
  type: SmartLinkWidgetType.socialLinks,
  view: ({ smartLinkResponse }) => <SocialLinksWidgetContent socialLinks={smartLinkResponse.socialLinks} />,
  embed: ({ smartLinkResponse }) => <SocialLinksWidgetContent socialLinks={smartLinkResponse.socialLinks} />
};
