import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

export const tidal: MusicService = {
  id: 'tidal',
  shortName: 'Tidal',
  name: 'Tidal',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: true,
  isSupported: true,
  color: '#000000',
  logo: {
    light: nextAsset('images/music-services/tidal-light.svg'),
    dark: nextAsset('images/music-services/tidal-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/tidal-light-padding.svg'),
    dark: nextAsset('images/music-services/tidal-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/tidal-color-light.svg'),
    dark: nextAsset('images/music-services/tidal-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/tidal-color-light-padding.svg'),
    dark: nextAsset('images/music-services/tidal-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/tidal-dark.svg')
};
