import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

export const boomplay: MusicService = {
  id: 'boomplay',
  shortName: 'Boomplay',
  name: 'Boomplay',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: true,
  isSupported: true,
  color: '#FD0164',
  logo: {
    light: nextAsset('images/music-services/boomplay-light.svg'),
    dark: nextAsset('images/music-services/boomplay-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/boomplay-light-padding.svg'),
    dark: nextAsset('images/music-services/boomplay-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/boomplay-color-light.svg'),
    dark: nextAsset('images/music-services/boomplay-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/boomplay-color-light-padding.svg'),
    dark: nextAsset('images/music-services/boomplay-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/boomplay-dark.svg')
};
