import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

export const yandexMusic: MusicService = {
  id: 'yandex-music',
  shortName: 'Yandex Music',
  name: 'Yandex Music',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: false,
  isSupported: true,
  color: '#ff3333',
  logo: {
    light: nextAsset('images/music-services/yandex-light.svg'),
    dark: nextAsset('images/music-services/yandex-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/yandex-light-padding.svg'),
    dark: nextAsset('images/music-services/yandex-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/yandex-color-light.svg'),
    dark: nextAsset('images/music-services/yandex-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/yandex-color-light-padding.svg'),
    dark: nextAsset('images/music-services/yandex-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/yandex-color-dark.svg')
};
