import React from 'react';
import { SmartLinkWidget } from './types';
import { PublicAccountSmartLinkAPIResponse, SerializedProfileWidget, SmartLinkWidgetType } from '../types';

const ProfileWidgetContent: React.FC<{ smartLinkResponse: PublicAccountSmartLinkAPIResponse }> = ({
  smartLinkResponse
}) => (
  <div className="smartlink-artist">
    <div
      style={{
        backgroundImage: `url("${smartLinkResponse.account.avatarProcessedUrl}")`
      }}
      className="smartlink-artist-avatar"
    />
    <h1>{smartLinkResponse.account.name}</h1>
  </div>
);

export const ProfileWidget: SmartLinkWidget<SerializedProfileWidget> = {
  type: SmartLinkWidgetType.profile,
  view: ({ smartLinkResponse }) => <ProfileWidgetContent smartLinkResponse={smartLinkResponse} />,
  embed: ({ smartLinkResponse }) => <ProfileWidgetContent smartLinkResponse={smartLinkResponse} />
};
