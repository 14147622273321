import React, { useCallback } from 'react';
import { config } from '../../config/config';
import { SMLPreviewMessageData, SMLPreviewMessages } from './types';

export const useSendMessageToParent = (message: SMLPreviewMessages, widgetUUID: string) => {
  const sendMessage = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      const data: SMLPreviewMessageData = {
        type: message,
        widgetUUID
      };
      window.top?.postMessage(data, config.smartlinksAppDomain); // for browser's iFrame
      window.top?.postMessage(data, 'app.smartlinksapp.com'); // TODO: for old domain, can be removed after migration
      window.top?.postMessage(data, 'file://'); // for Electron's iFrame
      window.top?.postMessage(data, 'http://127.0.0.1:3003'); // for Electron's local development
      (window as any).ReactNativeWebView?.postMessage(JSON.stringify(data)); // for ReactNative webview
    },
    [message, widgetUUID]
  );

  return sendMessage;
};
