'use client';

import React, { useEffect, useMemo, useState } from 'react';
import Head from 'next/head';
import { SmartLinkErrorPage } from './SmartLinkErrorPage';
import { SmartLinkNotFoundPage } from './SmartLinkNotFoundPage';
import { PublicAccountSmartLinkAPIResponse, SMLAppMessages, SMLPageProps } from './smartLinks/types';
import { SmartLink } from './smartLinks/SmartLink';
import { SmartLinkContext } from '../utils/SmartLinkContext';
import { useListenForParentIframeMessage } from './smartLinks/useListenForParentIframeMessage';
import { marketingParamsToStore, storeMarketingValue } from '../components/googleAnalytics';
import { getParameterByName } from '../components/utils';
import { useSmartLinkDetails } from './useSmartLinkDetails';

const SmartLinkBase: React.FC<SMLPageProps> = ({ smartLinkResponse, error, isArtist, isEmbed, theme, isEditMode }) => {
  useEffect(() => {
    marketingParamsToStore.forEach((key) => {
      const value = getParameterByName(key, window.location.href);
      if (value) {
        storeMarketingValue(key, value);
      }
    });
  }, []);

  const [smartLinkResponseRefreshed, setSmartLinkResponseRefreshed] = useState(smartLinkResponse);
  const [themeRefreshed, setThemeRefreshed] = useState(theme);
  const context = useMemo(
    () => ({
      smartLinkResponse: smartLinkResponseRefreshed,
      isArtist,
      isEmbed,
      isEditMode,
      theme: themeRefreshed
    }),
    [smartLinkResponseRefreshed, isArtist, isEmbed, isEditMode, themeRefreshed]
  );

  useListenForParentIframeMessage(SMLAppMessages.smartLinkUpdate, (event) => {
    const { smartLink } = event.data;
    const smartLinkVersion = smartLink.recentSmartLinkVersion ?? smartLink.publishedSmartLinkVersion;

    setThemeRefreshed(smartLinkVersion.theme);
    setSmartLinkResponseRefreshed((response) => ({ ...response, smartLink } as PublicAccountSmartLinkAPIResponse));
  });
  useSmartLinkDetails(smartLinkResponse);
  useEffect(() => {
    document.body.className = `theme-${themeRefreshed}`;
  }, [themeRefreshed]);
  if (!smartLinkResponse && error) {
    return <SmartLinkErrorPage />;
  }

  if (!smartLinkResponseRefreshed || !smartLinkResponse) {
    return <SmartLinkNotFoundPage />;
  }
  return (
    <SmartLinkContext.Provider value={context}>
      <Head>
        <link rel="shortcut icon" type="image/jpg" href={smartLinkResponse.account.avatarProcessedUrl} />
      </Head>
      <body className={`theme-${themeRefreshed}`}>
        <div className="h-100 d-flex flex-column smartLinks">
          <SmartLink smartLinkResponse={smartLinkResponseRefreshed} isEmbed={isEmbed} isEditMode={isEditMode} />
        </div>
      </body>
    </SmartLinkContext.Provider>
  );
};
export const SmartLinkBasePage = React.memo(SmartLinkBase);
