import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

export const qqMusic: MusicService = {
  id: 'qq-music',
  shortName: 'QQ音乐',
  name: 'QQ Music (QQ 音乐)',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: false,
  isSupported: true,
  color: '#fbdc4b',
  logo: {
    light: nextAsset('images/music-services/qq-light.svg'),
    dark: nextAsset('images/music-services/qq-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/qq-light-padding.svg'),
    dark: nextAsset('images/music-services/qq-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/qq-color-light.svg'),
    dark: nextAsset('images/music-services/qq-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/qq-color-light-padding.svg'),
    dark: nextAsset('images/music-services/qq-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/qq-color-dark.svg')
};
