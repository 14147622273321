import { SerializedAccountTracker } from '../../containers/smartLinks/types';

export type TrackerImplementationHook = (trackers: SerializedAccountTracker[] | undefined) => {
  initTracker: (() => void) | null;
  onPageView: (path: string) => void;
  onConsentGiven: (() => void) | null;
  onEvent: (event: TrackerEvent, params?: Record<string, string | undefined>) => void;
  trackerId: string;
};

export enum TrackerEvent {
  pageview = 'pageview', // native Plausible event, cannot be changed
  linkClicked = 'Link Clicked',
  socialLinkClicked = 'Social Link Clicked'
}

export enum FYMTrackerEvent {
  footerClicked = 'Footer Clicked'
}
