import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

export const deezer: MusicService = {
  id: 'deezer',
  shortName: 'Deezer',
  name: 'Deezer',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: false,
  isSupported: true,
  color: '#23232D',
  logo: {
    light: nextAsset('images/music-services/deezer-light.svg'),
    dark: nextAsset('images/music-services/deezer-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/deezer-light-padding.svg'),
    dark: nextAsset('images/music-services/deezer-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/deezer-color-light.svg'),
    dark: nextAsset('images/music-services/deezer-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/deezer-color-light-padding.svg'),
    dark: nextAsset('images/music-services/deezer-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/deezer-color-dark.svg')
};
