import { get, set } from 'local-storage';

interface MarketingValue {
  timestamp: number;
  value: string;
}
export const marketingParamsToStore = ['ref', 'utm_source', 'utm_campaign', 'utm_content'];

export const storeMarketingValue = (key: string, value: string): void => {
  const fullKey = `marketing.${key}`;

  if (value) {
    const now = Math.floor(Date.now() / 1000);
    const data = {
      value,
      timestamp: now
    };
    set<MarketingValue>(fullKey, data);
  }
};

export const marketingValue: (key: string) => string | undefined = (key): string | undefined => {
  const fullKey = `marketing.${key}`;
  const storedData = get<MarketingValue>(fullKey);
  if (!storedData) {
    return undefined;
  }

  try {
    const { timestamp, value } = storedData;
    const now = Math.floor(Date.now() / 1000);
    const difference = now - timestamp;

    if (difference < 28 * 24 * 3600) {
      return value;
    }
  } catch (e) {
    return '';
  }
  return '';
};
