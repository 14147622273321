import { useCallback, useMemo } from 'react';
import { config } from '../../../config/config';
import { SerializedAccountTrackerType } from '../../../containers/smartLinks/types';
import { FYMTrackerEvent, TrackerImplementationHook } from '../types';

const callPlausibleEventManually = async (
  plausibleDomain: string,
  plausibleUrl: string,
  event: string,
  eventProps?: Record<string, string | undefined>
) => {
  if (!config.plausible) {
    return;
  }
  await fetch(`${plausibleUrl}api/event`, {
    body: JSON.stringify({
      n: event, // name
      u: window.location.href, // url
      d: plausibleDomain, // domain
      w: window.innerWidth, // screen_width
      r: document.referrer, // referrer
      p: JSON.stringify(eventProps) // props, https://github.com/plausible/analytics/discussions/1570
    }),
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST'
  });
};

function usePlausibleEvent(plausibleInstanceInfo: { domains: string; url: string }[]) {
  return useCallback(
    (event: string, eventProps?: Record<string, string | undefined>) => {
      plausibleInstanceInfo.forEach((pii) => {
        callPlausibleEventManually(pii.domains, pii.url, event, eventProps).catch(console.error);
      });
    },
    [plausibleInstanceInfo]
  );
}

export const usePlausibleTracker: TrackerImplementationHook = (trackers) => {
  const plausibleTrackers = useMemo(
    () => trackers?.filter((t) => t.type === SerializedAccountTrackerType.plausible),
    [trackers]
  );
  const plausibleInstances = useMemo(() => {
    if (!plausibleTrackers) {
      return [];
    }
    const temp: Record<string, string> = {};
    plausibleTrackers.forEach((t) => {
      const url = t.data?.url ?? '';
      let domains = t.data?.trackerId ?? '';

      // We also track ALL the smartlink preview pages in one dashboard
      if (config.plausible && url === config.plausible.url) {
        domains = `${domains},${config.plausible.siteId}`;
      }
      if (temp[url]) {
        temp[url] = `${temp[url]},${domains}`;
      } else {
        temp[url] = domains;
      }
    });
    return Object.keys(temp).map((k) => {
      const t = temp[k];
      return {
        domains: t,
        url: k
      };
    });
  }, [plausibleTrackers]);

  const plausibleEvent = usePlausibleEvent(plausibleInstances);
  return useMemo(
    () => ({
      initTracker: null,
      onPageView: () => {
        plausibleEvent('pageview', undefined);
      },
      onEvent: (event, params) => {
        plausibleEvent(event, params);
      },
      onConsentGiven: null,
      trackerId: 'plausible'
    }),
    [plausibleEvent]
  );
};

export const useFYMPlausible = (event: FYMTrackerEvent, params?: Record<string, string | undefined>) => {
  const plausibleInstanceInfo = useMemo(
    () =>
      config.plausible
        ? [
            {
              domains: config.plausible.siteId,
              url: config.plausible.url
            }
          ]
        : [],
    []
  );
  const onEvent = usePlausibleEvent(plausibleInstanceInfo);
  return useCallback(() => {
    onEvent(event, params);
  }, [event, onEvent, params]);
};
