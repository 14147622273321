import { MusicService } from './types';
import { nextAsset } from '../../nextAsset';

export const gaana: MusicService = {
  id: 'gaana',
  shortName: 'Gaana',
  name: 'Gaana',
  isComingSoon: false,
  areStatsSupported: false,
  areStatsComingSoon: false,
  isSupported: true,
  color: '#e72c30',
  logo: {
    light: nextAsset('images/music-services/gaana-light.svg'),
    dark: nextAsset('images/music-services/gaana-dark.svg')
  },
  logoPadding: {
    light: nextAsset('images/music-services/gaana-light-padding.svg'),
    dark: nextAsset('images/music-services/gaana-dark-padding.svg')
  },
  logoColor: {
    light: nextAsset('images/music-services/gaana-color-light.svg'),
    dark: nextAsset('images/music-services/gaana-color-dark.svg')
  },
  logoColorPadding: {
    light: nextAsset('images/music-services/gaana-color-light-padding.svg'),
    dark: nextAsset('images/music-services/gaana-color-dark-padding.svg')
  },
  logoOnBackground: nextAsset('images/music-services/gaana-dark.svg')
};
