'use client';

import React from 'react';
import { Button } from 'reactstrap';
import { useSearchParams } from 'next/navigation';
import { useHasMounted } from '../hooks/useHasMounted';
import { useCookieConsent } from './useCookieConsent';

export const CookieConsentBar: React.FC = () => {
  const searchParams = useSearchParams();
  const raw = searchParams.get('raw') === '1';
  const hasMounted = useHasMounted();
  const { consented, onConsentGiven } = useCookieConsent();
  if (consented || !hasMounted || raw) {
    return null;
  }
  return (
    <>
      <div style={{ height: 40 }} />
      <div className="mx-auto my-2 cookie-consent">
        <Button onClick={onConsentGiven} color="primary" block>
          This website uses cookies to enhance users experience. Please tap here to acknowledge that.
        </Button>
      </div>
    </>
  );
};
